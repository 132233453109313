import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isEqual, isFunction } from "lodash";
import * as actions from "../_redux/announcementActions";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "./utils/TableUiHelpers";
import { Pagination } from "../../../_metronic/_partials/controls";
import { AnnouncementDeleteDialog } from "./DeleteAnnouncement";
import AnnouncementModal from "./AnnouncementModal";
import PreviewModal from "./PreviewModal";
import { useHistory } from "react-router-dom";
import { AcknowledgementModal } from "./AcknowledgementsModal";
import { SendEmailModal } from "./SendEmailModal";

export default function TableView({}) {
  const history = useHistory();
  const [acknowledgementId, setAcknowledgementId] = useState(undefined);
  const [showAcknowledgementsModal, setShowAcknowledgementsModal] = useState(
    false
  );
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [ids, setIds] = useState([]);
  const [queryParams, setQueryParamsBase] = useState(uiHelpers.initialFilter);
  const [showModal, setShowModal] = useState(false);
  const [previewData, setPreviewData] = useState({ title: "", highlight: "" });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [id, setId] = useState(undefined);

  const onHide = () => {
    setAcknowledgementId(undefined);
    setShowAcknowledgementsModal(false);
  };

  const onHideEmail = () => {
    setShowEmailModal(false);
  };

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const { currentState, userState } = useSelector(
    state => ({ currentState: state.announcement, userState: state.auth }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    setIds([]);
    dispatch(actions.fetchAnnouncements(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const { totalCount, entities, listLoading } = currentState;

  function openDeleteAnnouncementDialog(id) {
    setId(id);
    setShowModal(true);
  }

  function openEditAnnouncementDialog(id) {
    setId(id);
    setShowEditModal(true);
  }
  function openViewAnnouncementPage(data) {
    setPreviewData(data);
    setShowPreviewModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  };

  const handleClosePreviewModal = () => {
    setPreviewData({ title: "", highlight: "" });
    setShowPreviewModal(false);
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  // Lead Redux state

  // Table columns
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "200px"
      }
    },
    {
      dataField: "createdBy",
      text: "CreatedBy",
      sort: true,
      sortCaret: sortCaret
    },
    // {
    //   dataField: "highlight",
    //   text: "Highlight",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.HighlightColumnFormatter
    // },
    {
      dataField: "dueDate",
      text: "Due Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PriorityColumnFormatter
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openDeleteAnnouncementDialog,
        openEditAnnouncementDialog,
        openViewAnnouncementPage,
        openViewAcknowledgementsPage: id => {
          setAcknowledgementId(id);
          setShowAcknowledgementsModal(true);
          // history.push(`/acknowledgement/${id}`);
        },
        openSendListEmail: id => {
          setId(id);
          setShowEmailModal(true);
        }
      },
      classes: "text-center pr-3",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "200px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber
  };
  return (
    <>
      {showAcknowledgementsModal && (
        <AcknowledgementModal
          id={acknowledgementId}
          show={showAcknowledgementsModal}
          onHide={onHide}
        />
      )}
      {showEmailModal && (
        <SendEmailModal
          id={id}
          showModal={showEmailModal}
          handleClose={onHideEmail}
        />
      )}
      <PreviewModal
        showModal={showPreviewModal}
        handleClose={handleClosePreviewModal}
        ele={previewData}
      />
      <div className="card card-custom gutter-b">
        <AnnouncementDeleteDialog
          id={id}
          showModal={showModal}
          handleClose={handleClose}
          queryParams={queryParams}
          setIds={setIds}
        />
        <AnnouncementModal
          showModal={showEditModal}
          handleClose={handleEditModalClose}
          id={id}
        />
        {/*begin::Body*/}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap ">
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="id"
                    data={entities === undefined ? [] : entities}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    selectRow={getSelectRow({
                      entities,
                      ids: ids,
                      setIds: setIds
                    })}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={entities} />
                    <NoRecordsFoundMessage entities={entities} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </div>
      </div>
    </>
  );
}
