import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isEqual, isFunction } from "lodash";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../_metronic/_helpers";
import * as actions from "../../modules/Acknowledgements/_redux/acknowledgementActions";
import { Modal, Table, Badge } from "react-bootstrap";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import * as uiHelpers from "./utils/TableUiHelpers";
import * as columnFormatters from "../../modules/Acknowledgements/pages/acknowledgement-table/column-formatters";
import { Pagination } from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";

export function AcknowledgementModal({ id, show, onHide }) {
  const [ids, setIds] = useState([]);
  const history = useHistory();
  const [queryParams, setQueryParamsBase] = useState(uiHelpers.initialFilter);
  const [records, setRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const { currentState, userState } = useSelector(
    state => ({ currentState: state.acknowledgements }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchAcknowledgements(queryParams, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const { totalCount, entities, listLoading } = currentState;

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "userName",
      text: "User Name",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter
    }
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber
  };

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {listLoading && <ModalProgressBar />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            View Acknowledgements
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card card-custom gutter-b">
            <div className="card-body d-flex align-items-center justify-content-between flex-wrap ">
              <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
              >
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <Pagination
                      isLoading={listLoading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        bordered={false}
                        remote
                        keyField="id"
                        data={entities === undefined ? [] : entities}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        selectRow={getSelectRow({
                          entities,
                          ids: ids,
                          setIds: setIds
                        })}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={entities} />
                        <NoRecordsFoundMessage entities={entities} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
