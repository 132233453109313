import React from "react";
import Select from "react-select";

function MySelect({ onChange, onBlur, data, value, error, touched, name }) {
  const handleChange = value => {
    // this is going to call setFieldValue and manually update values.preferences
    if (value === null) {
      onChange(name, []);
    } else {
      onChange(name, value);
    }
  };
  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.preferences
    onBlur(name, true);
  };

  return (
    <div className="form-group">
      <Select
        isMulti
        name={name}
        options={data}
        className=""
        placeholder={`Select ${name}`}
        classNamePrefix="select"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
      {!!error && touched ? (
        <div
          style={{
            color: "#F64E60",
            marginTop: ".5rem",
            fontSize: "0.9rem",
            fontWeight: "400"
          }}
        >
          {error}
        </div>
      ) : (
        <span className="form-text text-muted">
          Please select <b>{name}</b>
        </span>
      )}
    </div>
  );
}

export default MySelect;
