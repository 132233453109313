import React from "react";
import { AnnouncementStatusCssClasses } from "../utils/TableUiHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      AnnouncementStatusCssClasses[row.status]
    } label-inline`}
  >
    {row.status}
  </span>
);
