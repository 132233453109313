import axios from "axios";

export const ANNOUNCEMENT_URL =
  process.env.REACT_APP_API_ENDPOINT + "/announcement";

// READ
export function getAllSetting() {
  return axios.get(ANNOUNCEMENT_URL);
}
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSetting(queryParams) {
  return axios.get(`${ANNOUNCEMENT_URL}`);
}

export function getAnnouncementById(announcementId) {
  return axios.get(`${ANNOUNCEMENT_URL}/${announcementId}`);
}

// UPDATE => PUT: update the procuct on the server
export function updateSetting(setting) {
  return axios.put(`${ANNOUNCEMENT_URL}/`, { setting });
}

export function createAnnouncement(announcement) {
  return axios.post(ANNOUNCEMENT_URL, { announcement });
}

export function updateAnnouncement(announcement) {
  return axios.put(`${ANNOUNCEMENT_URL}/${announcement.id}`, { announcement });
}

export function acknowledgeAnnouncement(id) {
  return axios.post(`${ANNOUNCEMENT_URL}/acknowledge/${id}`);
}

export function emailAnnouncement(id) {
  return axios.post(`${ANNOUNCEMENT_URL}/email/${id}`);
}

export function fetchAnnouncements(queryParams) {
  return axios.get(`${ANNOUNCEMENT_URL}?query=${JSON.stringify(queryParams)}`);
}

export function fetchProfilesAndDepartments() {
  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/users/getAllDepartmentsAndProfiles`
  );
}

export function fetchPreviewAnnouncements(queryParams) {
  return axios.get(
    `${ANNOUNCEMENT_URL}/preview?query=${JSON.stringify(queryParams)}`
  );
}

export function deleteAnnouncement(accountId) {
  return axios.delete(`${ANNOUNCEMENT_URL}/${accountId}`);
}
