import React from "react";
import { Button, Modal } from "react-bootstrap";

function ViewBody({ body, show, handleClose, title }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: body }} />
          {/* <p>{body}</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewBody;
