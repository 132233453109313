import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { DashboardPage } from "./pages/DashboardPage";
const AcknowledgementPage = lazy(() =>
  import("./modules/Acknowledgements/acknowledgement")
);
const SettingsPage = lazy(() => import("./modules/Settings/SettingsPage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/acknowledgement" to="/dashboard" />
        <ContentRoute path="/dashboard" exact component={DashboardPage} />
        {/* <Route path="/acknowledgement/:id" component={AcknowledgementPage} /> */}

        {/* <Route path="/acknowledgements" component={SettingsPage} /> */}
        <Redirect to="error/error-v2" />
      </Switch>
    </Suspense>
  );
}
