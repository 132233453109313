import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { announcementSlice } from "../app/pages/_redux/announcementSlice";
import { acknowledgementSlice } from "../app/modules/Acknowledgements/_redux/acknowledgementSlice";
import { settingsSlice } from "../app/modules/Settings/_redux/settingsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  announcement: announcementSlice.reducer,
  acknowledgements: acknowledgementSlice.reducer,
  settings: settingsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
