import React from "react";

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}
export const HighlightColumnFormatter = (cellContent, row) => (
  <>
    <span>{truncateString(row.highlight, 40)}</span>
  </>
);
