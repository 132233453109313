import React from "react";
import { Modal } from "react-bootstrap";

function PreviewModal({ showModal, handleClose, ele }) {
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered size="md">
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            {/*begin::Row*/}
            <div className="row">
              <div className="col-12">
                {/*begin::Engage Widget 9*/}
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body d-flex p-0">
                    <div
                      className="flex-grow-1 p-20 card-rounded flex-grow-1 bgi-no-repeat"
                      // style={{
                      //   backgroundPosition: "calc(100% + 0.5rem) bottom",
                      //   backgroundSize: "50% auto",
                      //   backgroundImage: "url(media/svg/humans/custom-10.svg)"
                      // }}
                    >
                      <h2 className="text-dark pb-5 font-weight-bolder">
                        {ele.title}
                      </h2>
                      <div
                        style={{
                          maxWidth: "90%"
                        }}
                      >
                        <p
                          className="text-dark-50 pb-5 font-size-h5"
                          style={{
                            minHeight: "110px"
                          }}
                        >
                          {ele.highlight}
                        </p>
                        <div className="float-right">
                          <button
                            type="button"
                            className="btn btn-primary font-weight-bold py-2 px-6 mr-2"
                          >
                            View More
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger font-weight-bold py-2 px-6 ml-2"
                          >
                            Acknowledge
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*end::Engage Widget 9*/}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PreviewModal;
