import * as requestFromServer from "./acknowledgementCrud";
import { acknowledgementSlice, callTypes } from "./acknowledgementSlice";

const { actions } = acknowledgementSlice;

export const fetchAcknowledgements = (queryParams, id) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.findAcknowledgement(
      queryParams,
      id
    );
    const { totalCount, entities } = response.data.data;
    dispatch(actions.acknowledgementsFetched({ totalCount, entities }));
  } catch (error) {
    error.clientMessage = "Can't find acknowledgement";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const deleteAcknowledgement = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAcknowledgement(id)
    .then(response => {
      dispatch(actions.acknowledgementDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete acknowledgement";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAcknowledgements = ids => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteAcknowledgements(ids);
    if (response.data.status) {
      dispatch(actions.acknowledgementsDeleted({ ids }));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't delete acknowledgements";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};
