import axios from "axios";

export const ACKNOWLEDGEMENT_URL =
  process.env.REACT_APP_API_ENDPOINT + "/announcement";

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAcknowledgement(queryParams, id) {
  return axios.get(
    `${ACKNOWLEDGEMENT_URL}/${id}/acknowledgements?query=${JSON.stringify(
      queryParams
    )}`
  );
}

// DELETE => delete the acknowledgement from the server
export function deleteAcknowledgement(acknowledgementId) {
  return axios.delete(`${ACKNOWLEDGEMENT_URL}/${acknowledgementId}`);
}

// DELETE Acknowledgement by ids
export function deleteAcknowledgements(ids) {
  return axios.post(`${ACKNOWLEDGEMENT_URL}/deleteMultiple`, {
    acknowledgements: ids
  });
}

export function getManagers() {
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/managers`);
}
