// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import MySelect from "./components/MySelect";
// import { default as Select2 } from "react-select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField
} from "../../../../_metronic/_partials/controls";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Validation schema
const AnnouncementEditSchema = Yup.object().shape({
  title: Yup.string()
    .min(5, "Minimum 5 characters")
    .max(55, "Maximum 55 characters")
    .required("This field is required"),
  highlight: Yup.string()
    .min(50, "Minimum 50 characters")
    .max(135, "Maximum 135 characters")
    .required("This field is required"),
  departments: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string()
    })
  ),
  profiles: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string()
    })
  ),
  dueDate: Yup.mixed()
    .nullable(false)
    .required("Due date is required"),
  // priority: Yup.string().required("This field is required"),
  body: Yup.string(),
  status: Yup.string()
});

export function AnnouncementEditForm({
  saveAnnouncement,
  announcement,
  actionsLoading,
  handleClose,
  profiles,
  departments
}) {
  const [editorDefaultData, setEditorDefaultData] = useState("<br />");
  const [customEditor, setCustomEditor] = useState({});
  const btnRef = useRef();
  const saveAnnouncementClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  useEffect(() => {
    if (announcement.id && announcement.id !== undefined) {
      setEditorDefaultData(announcement.body);
    }
    return function cleanup() {
      setEditorDefaultData("<br />");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcement]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={announcement}
        validationSchema={AnnouncementEditSchema}
        onSubmit={values => {
          const payload = {
            ...values,
            profiles: values?.profiles?.map(t => t.value),
            departments: values?.departments?.map(t => t.value),
            body: customEditor.getData()
          };
          saveAnnouncement(payload);
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          values
        }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Field
                      name="title"
                      component={Input}
                      placeholder="Title"
                      label="Announcement Title"
                    />
                  </div>
                  <div className="col-lg-6">
                    <DatePickerField
                      autoComplete="off"
                      name="dueDate"
                      label="Due Date"
                      minDate={new Date()}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="highlight"
                      component={Input}
                      placeholder="Highlight"
                      label="Announcement Highlight"
                    />
                  </div>
                </div>

                {announcement.id && (
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Select name="status" label="Announcement Status">
                        {["draft", "published"].map((value, index) => (
                          <option key={index} value={value}>
                            {value.toUpperCase()}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <div className="col-lg-12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorDefaultData}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        setCustomEditor(editor);
                        // console.log("Editor is ready to use!", editor);
                      }}
                    />
                  </div>
                </div>

                <h3 className="mb-5">Accessibility Settings</h3>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <MySelect
                      name={"profiles"}
                      value={values.profiles}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.profiles}
                      touched={touched.profiles}
                      data={profiles.map(record => ({
                        value: record.id,
                        label: record.Name
                      }))}
                    />
                  </div>
                  <div className="col-lg-6">
                    <MySelect
                      name={"departments"}
                      value={values.departments}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.departments}
                      touched={touched.departments}
                      data={departments.map(record => ({
                        value: record.id,
                        label: record.Name
                      }))}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={() => handleSubmit()}
                ></button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={saveAnnouncementClick}
                className="btn btn-primary btn-elevate"
              >
                Submit
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
