import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AnnouncementEditDialogHeader } from "./AnnouncementForm/AnnouncementEditDialogHeader";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/announcementActions";
import { AnnouncementEditForm } from "./AnnouncementForm/AnnouncementEditForm";

export default function AnnouncementModal({ showModal, handleClose, id }) {
  const [announcementPayload, setAnnouncementPayload] = useState(
    announcementForEdit
  );
  let initAnnouncement = {
    id: undefined,
    title: "",
    highlight: "",
    body: ""
  };

  const dispatch = useDispatch();
  const {
    actionsLoading,
    announcementForEdit,
    profiles,
    departments
  } = useSelector(
    state => ({
      actionsLoading: state.announcement.actionsLoading,
      announcementForEdit: state.announcement.announcementForEdit,
      profiles: state.announcement.profiles,
      departments: state.announcement.departments
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting account by id
    dispatch(actions.fetchAnnouncement(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (announcementForEdit) {
      let _newDept = departments
        .filter(ele =>
          announcementForEdit.departments.includes(ele.id.toString())
        )
        .map(e => ({ value: e.id, label: e.Name }));
      let _newProf = profiles
        .filter(ele => announcementForEdit.profiles.includes(ele.id.toString()))
        .map(e => ({ value: e.id, label: e.Name }));

      setAnnouncementPayload({
        ...announcementForEdit,
        departments: _newDept,
        profiles: _newProf
      });
    }
  }, [departments, profiles, announcementForEdit]);

  // server request for saving Announcement
  const saveAnnouncement = announcement => {
    if (!id) {
      // server request for creating account
      dispatch(actions.createAnnouncement(announcement)).then(res => {
        if (res.data && res.data.status === true) {
          handleClose();
        } else {
          let message = (res.data && res.data.message) || res.clientMessage;
          toast.warn(message);
        }
      });
    } else {
      // server request for updating account
      dispatch(actions.updateAnnouncement(announcement)).then(res => {
        if (res.data && res.data.status === true) {
          handleClose();
        } else {
          let message = (res.data && res.data.message) || res.clientMessage;
          toast.warn(message);
        }
      });
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <AnnouncementEditDialogHeader id={id} />
        <AnnouncementEditForm
          saveAnnouncement={saveAnnouncement}
          actionsLoading={actionsLoading}
          announcement={announcementPayload || initAnnouncement}
          handleClose={handleClose}
          profiles={profiles}
          departments={departments}
        />
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Create
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
