import {
  initialPreviewFilter,
  initialFilter
} from "../components/utils/TableUiHelpers";
import * as requestFromServer from "./announcementCrud";
import { announcementSlice, callTypes } from "./announcementSlice";

const { actions } = announcementSlice;
export const fetchAnnouncement = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.announcementFetched({ announcementForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAnnouncementById(id)
    .then(response => {
      const announcement = response.data.data;
      dispatch(
        actions.announcementFetched({ announcementForEdit: announcement })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find announcement";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSetting = setting => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.updateSetting(setting);
    if (response.data.status) {
      dispatch(fetchAnnouncement());
      // dispatch(actions.settingUpdated({ setting }));
      return response;
    }
  } catch (error) {
    error.clientMessage = "Can't update setting";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const createAnnouncement = announcementForCreation => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createAnnouncement(
      announcementForCreation
    );
    if (response.data.status) {
      const announcement = response.data.data;
      dispatch(actions.announcementCreated({ announcement }));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't create announcement";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const updateAnnouncement = announcement => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.updateAnnouncement(announcement);
    if (response.data.status) {
      // dispatch(actions.announcementUpdated({ announcement }));
      let queryParams = initialFilter;
      dispatch(fetchAnnouncements(queryParams));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't update announcement";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};
export const acknowledgeAnnouncement = id => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.acknowledgeAnnouncement(id);
    if (response.data.status) {
      let queryParams = initialPreviewFilter;
      let r = true;
      dispatch(fetchPreviewAnnouncements(queryParams, r));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't acknowledge announcement";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const fetchAnnouncements = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchAnnouncements(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data.data;
      dispatch(
        actions.announcementsFetched({
          totalCount,
          entities
        })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find announcements";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchPreviewAnnouncements = (
  queryParams,
  r = false
) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchPreviewAnnouncements(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data.data;
      dispatch(
        actions.announcementsPreviewFetched({
          totalCount,
          entities,
          r
        })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find announcements";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteAnnouncement = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAnnouncement(id)
    .then(response => {
      dispatch(actions.announcementDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete announcement";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProfilesAndDepartments = () => async dispatch => {
  try {
    const response = await requestFromServer.fetchProfilesAndDepartments();
    dispatch(
      actions.profilesAndDepartmentsFetched({
        data: response.data.data
      })
    );
  } catch (error) {
    error.clientMessage = "Can't find profiles";
  }
};

export const emailAnnouncement = id => async dispatch => {
  try {
    requestFromServer.emailAnnouncement(id);
  } catch (error) {
    console.log(error);
  }
};
