import React from "react";
import moment from "moment";

export const PriorityColumnFormatter = (cellContent, row) => (
  <>
    {row.dueDate ? (
      <span className={`font-bold font-primary`}>
        {moment(row.dueDate).format("LL")}
      </span>
    ) : (
      <span className={`font-bold font-primary`}>N/A</span>
    )}
  </>
);
