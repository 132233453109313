import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import PreviewView from "./components/PreviewView";
import TableView from "./components/TableView";
import Topbar from "./components/Topbar";
import { ToastContainer } from "react-toastify";
import * as actions from "./_redux/announcementActions";

export function DashboardPage() {
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchProfilesAndDepartments());
  }, []);

  return (
    <>
      <>
        {" "}
        <ToastContainer />
        <Topbar viewMode={viewMode} setViewMode={setViewMode} />
        {viewMode ? (
          <PreviewView listLoading={false} entities={[]} totalCount={0} />
        ) : (
          <TableView />
        )}
      </>
    </>
  );
}
