import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AnnouncementModal from "./AnnouncementModal";
import * as actions from "../_redux/announcementActions";
import { useDispatch } from "react-redux";

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    "&$checked": {
      color: purple[500]
    },
    "&$checked + $track": {
      backgroundColor: purple[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

export default function Topbar({ viewMode, setViewMode }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleChange = event => {
    setViewMode(event.target.checked);
  };

  const handleShow = () => {
    dispatch(actions.fetchAnnouncement(null));
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <AnnouncementModal
        showModal={showModal}
        handleClose={handleClose}
        id={undefined}
      />
      <div className="card card-custom gutter-b">
        {/*begin::Body*/}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap py-3">
          {/*begin::Info*/}
          <div className="d-flex align-items-center mr-2 py-2">
            {/*begin::Title*/}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleShow}
            >
              New Announcement
            </button>

            <div className="d-flex mr-3"></div>

            {/*end::Title*/}
          </div>
          {/*end::Info*/}
          <span className="float-right">
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <b>View:</b> Table
                </Grid>
                <Grid item>
                  <PurpleSwitch
                    checked={viewMode}
                    onChange={handleChange}
                    value="checkedA"
                  />
                </Grid>
                <Grid item>Preview</Grid>
              </Grid>
            </Typography>
          </span>
        </div>
        {/*end::Body*/}
      </div>
    </>
  );
}
