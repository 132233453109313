import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialAnnouncementState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  previewList: [],
  previewListCount: 0,
  profiles: [],
  departments: [],
  announcementForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const announcementSlice = createSlice({
  name: "announcement",
  initialState: initialAnnouncementState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    announcementFetched: (state, action) => {
      state.actionsLoading = false;
      state.announcementForEdit = action.payload.announcementForEdit;
      state.error = null;
    },
    announcementCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.announcement);
    },
    announcementDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    announcementsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      // if (r === true) toast.success("Acknowledged!...");
    },
    announcementsPreviewFetched: (state, action) => {
      const { totalCount, entities, r } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.previewList = entities;
      state.previewListCount = totalCount;
      // if (r === true) toast.success("Acknowledged!...");
    },
    announcementUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.announcement.id) {
          return action.payload.announcement;
        }
        return entity;
      });
    },
    profilesAndDepartmentsFetched: (state, action) => {
      state.profiles = action.payload.data.profiles;
      state.departments = action.payload.data.departments;
      state.error = null;
    }
  }
});
