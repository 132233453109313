import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/announcementActions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ViewBody from "./ViewBody";
import Pagination from "./pagination/Pagination";
import * as uiHelpers from "../components/utils/TableUiHelpers";
import AcknowledgeButton from "./utils/AcknowledgeButton";

export default function PreviewView({}) {
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [pageProps, setPageProps] = useState(uiHelpers.initialPreviewFilter);

  const { currentState } = useSelector(
    state => ({ currentState: state.announcement }),
    shallowEqual
  );

  const { previewListCount, previewList, listLoading } = currentState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchPreviewAnnouncements(pageProps));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcknowledge = id => {
    setLoading(true);
    setLoadingId(id);
    dispatch(actions.acknowledgeAnnouncement(id)).then(res => {
      if (res.data && res.data.status === true) {
        toast.success("Acknowledged!...");
        setLoadingId(null);
        setLoading(false);
      } else {
        let message = (res.data && res.data.message) || res.clientMessage;
        setLoadingId(null);
        setLoading(false);
        toast.warn(message);
      }
    });
  };

  const openBody = (body, title) => {
    setTitle(title);
    setBody(body);
    setShow(true);
  };

  const handleClose = () => {
    setBody("");
    setTitle("");
    setShow(false);
  };
  // useEffect(() => {
  //   dispatch(actions.fetchAnnouncements(pageProps));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageProps.pageNumber]);
  return (
    <>
      {/*begin::Entry*/}
      {!listLoading && (
        <>
          <ViewBody
            show={show}
            body={body}
            handleClose={handleClose}
            title={title}
          />
          <div className="d-flex flex-column-fluid">
            {/*begin::Container*/}
            <div className="container">
              {/*begin::Row*/}
              <div className="row">
                {previewList.map(ele => (
                  <div className="col-xl-6" key={ele.id}>
                    {/*begin::Engage Widget 9*/}
                    <div className="card card-custom card-stretch gutter-b">
                      <div className="card-body d-flex p-0">
                        <div
                          className="flex-grow-1 p-20 card-rounded flex-grow-1 bgi-no-repeat"
                          // style={{
                          //   backgroundPosition: "calc(100% + 0.5rem) bottom",
                          //   backgroundSize: "50% auto",
                          //   backgroundImage: "url(media/svg/humans/custom-10.svg)"
                          // }}
                        >
                          <h2 className="text-dark pb-5 font-weight-bolder">
                            {ele.title}
                          </h2>
                          <div
                            style={{
                              maxWidth: "90%"
                            }}
                          >
                            <p
                              className="text-dark-50 pb-5 font-size-h5"
                              style={{
                                minHeight: "110px"
                              }}
                            >
                              {ele.highlight}
                            </p>
                            <div className="float-right">
                              <button
                                type="button"
                                className="btn btn-primary font-weight-bold py-2 px-6 mr-2"
                                onClick={() => openBody(ele.body, ele.title)}
                              >
                                View More
                              </button>
                              <AcknowledgeButton
                                loadingId={loadingId}
                                id={ele.id}
                                handleAcknowledge={handleAcknowledge}
                                loading={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Engage Widget 9*/}
                  </div>
                ))}
              </div>
              {/*end::Row*/}
              <Pagination
                className="pagination-bar"
                currentPage={pageProps.pageNumber}
                totalCount={previewListCount}
                pageSize={pageProps.pageSize}
                onPageChange={page => {
                  let newParams = {
                    ...pageProps,
                    pageNumber: page
                  };

                  setPageProps(newParams);
                  dispatch(actions.fetchPreviewAnnouncements(newParams));
                }}
              />
            </div>
            {/*end::Container*/}
          </div>
        </>
      )}
      {/*end::Entry*/}
    </>
  );
}
