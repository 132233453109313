/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    openEditAnnouncementDialog,
    openDeleteAnnouncementDialog,
    openViewAcknowledgementsPage,
    openConvertAnnouncementPage,
    openSendListEmail
  }
) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="send-email-tooltip">Email to list</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openSendListEmail(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
      <> </>
      <OverlayTrigger
        overlay={
          <Tooltip id="announcement-edit-tooltip">Acknowledgements</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openViewAcknowledgementsPage(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Repeat.svg")} />
          </span>
        </a>
      </OverlayTrigger>
      <> </>

      <OverlayTrigger
        overlay={<Tooltip id="announcement-edit-tooltip">Edit</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditAnnouncementDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>

      <OverlayTrigger
        overlay={<Tooltip id="announcement-delete-tooltip">Delete</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openDeleteAnnouncementDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
    </>
  );
};
