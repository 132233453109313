/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toast } from "react-toastify";
import * as actions from "../_redux/announcementActions";

export function SendEmailModal({ id, showModal, handleClose }) {
  // Announcement Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    state => ({ isLoading: state.announcement.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const emailAnnouncement = () => {
    // server request for deleting announcement by id
    dispatch(actions.emailAnnouncement(id)).then(() => {});
    toast.success("sending email. you will be notified when process is done!");
    handleClose();
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Email Announcement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && <span>Are you sure to email this announcement?</span>}
        {/* {isLoading && <span>Sending Email...</span>} */}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={emailAnnouncement}
            className="btn btn-success btn-elevate"
          >
            Send
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
